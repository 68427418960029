import { default as address5xkAbtWq87Meta } from "/workspace/pages/address.vue?macro=true";
import { default as agent9TSImmpu7aMeta } from "/workspace/pages/agent.vue?macro=true";
import { default as applyuT01IlqzmVMeta } from "/workspace/pages/apply.vue?macro=true";
import { default as assetsWcIGLqgGsJMeta } from "/workspace/pages/assets.vue?macro=true";
import { default as coming_45soon09efpPJjMFMeta } from "/workspace/pages/coming-soon.vue?macro=true";
import { default as completed5xzZkpIrjHMeta } from "/workspace/pages/completed.vue?macro=true";
import { default as credittIQXZGDUFHMeta } from "/workspace/pages/credit.vue?macro=true";
import { default as down_45payment25oeotCA5uMeta } from "/workspace/pages/down-payment.vue?macro=true";
import { default as emailihB3Z7ml9LMeta } from "/workspace/pages/email.vue?macro=true";
import { default as employmentZ3X9FeucQyMeta } from "/workspace/pages/employment.vue?macro=true";
import { default as finalizedp7M47PuEYMeta } from "/workspace/pages/finalize.vue?macro=true";
import { default as first_45timecPkR3r6iJ1Meta } from "/workspace/pages/first-time.vue?macro=true";
import { default as incomeAltukrMXDLMeta } from "/workspace/pages/income.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as loginGeKhs9qf7uMeta } from "/workspace/pages/login.vue?macro=true";
import { default as maintenancegZuggbbijfMeta } from "/workspace/pages/maintenance.vue?macro=true";
import { default as nameqOszIrlMqMMeta } from "/workspace/pages/name.vue?macro=true";
import { default as not_45foundmsvw9PaosCMeta } from "/workspace/pages/not-found.vue?macro=true";
import { default as credit_45serviceskRAWcV7vAfMeta } from "/workspace/pages/partners/credit-services.vue?macro=true";
import { default as dovlyMawblJsr90Meta } from "/workspace/pages/partners/dovly.vue?macro=true";
import { default as nafrfVTD3VRbzMeta } from "/workspace/pages/partners/naf.vue?macro=true";
import { default as novusqHarWkf3QCMeta } from "/workspace/pages/partners/novus.vue?macro=true";
import { default as paying_45rentLMD4WxAca6Meta } from "/workspace/pages/paying-rent.vue?macro=true";
import { default as index4PgMdfFXNjMeta } from "/workspace/pages/rates/index.vue?macro=true";
import { default as outzsTFaZAMqvMeta } from "/workspace/pages/rates/out.vue?macro=true";
import { default as referP1h2an0GtjMeta } from "/workspace/pages/refer.vue?macro=true";
import { default as registerHzIeMWcxyBMeta } from "/workspace/pages/register.vue?macro=true";
import { default as startEPNsMmPMMAMeta } from "/workspace/pages/start.vue?macro=true";
import { default as submitKHjcroZebiMeta } from "/workspace/pages/submit.vue?macro=true";
import { default as thank_45youeGzjVBwbMFMeta } from "/workspace/pages/thank-you.vue?macro=true";
import { default as alert3RsqCyOvTZMeta } from "/workspace/pages/ui/alert.vue?macro=true";
import { default as banner9xl4J7hskOMeta } from "/workspace/pages/ui/banner.vue?macro=true";
import { default as buttonsGfwAhs4SEsMeta } from "/workspace/pages/ui/buttons.vue?macro=true";
import { default as colors8szwstCzlKMeta } from "/workspace/pages/ui/colors.vue?macro=true";
import { default as didyouknowwGiriHZykFMeta } from "/workspace/pages/ui/didyouknow.vue?macro=true";
import { default as indexfHMywgrQ3bMeta } from "/workspace/pages/ui/index.vue?macro=true";
import { default as inputsXbtnwpYFkCMeta } from "/workspace/pages/ui/inputs.vue?macro=true";
import { default as letterPjw2T7fIAvMeta } from "/workspace/pages/ui/letter.vue?macro=true";
import { default as loading0cjLby1tlyMeta } from "/workspace/pages/ui/loading.vue?macro=true";
import { default as long_45layoutXmdCJcIDrbMeta } from "/workspace/pages/ui/long-layout.vue?macro=true";
import { default as modal0DRBVb3yGSMeta } from "/workspace/pages/ui/modal.vue?macro=true";
import { default as noticeG7mhlbr0o1Meta } from "/workspace/pages/ui/notice.vue?macro=true";
import { default as progressfgc0CqVd0RMeta } from "/workspace/pages/ui/progress.vue?macro=true";
import { default as short_45layoutPk6105zz2LMeta } from "/workspace/pages/ui/short-layout.vue?macro=true";
import { default as specialJbSKzMPS4pMeta } from "/workspace/pages/ui/special.vue?macro=true";
import { default as spinnerhzS9H8xPWkMeta } from "/workspace/pages/ui/spinner.vue?macro=true";
import { default as testimonialUypmLYgeRpMeta } from "/workspace/pages/ui/testimonial.vue?macro=true";
import { default as TooltipDEAWonyMqQMeta } from "/workspace/pages/ui/Tooltip.vue?macro=true";
import { default as typeTVmbB7OFirMeta } from "/workspace/pages/ui/type.vue?macro=true";
import { default as when_45are_45youDt7oBpPmQIMeta } from "/workspace/pages/when-are-you.vue?macro=true";
import { default as where_45are_45you9XMmjbKO7xMeta } from "/workspace/pages/where-are-you.vue?macro=true";
import { default as whos_45it_45forQo2doPJRhrMeta } from "/workspace/pages/whos-it-for.vue?macro=true";
export default [
  {
    name: "address",
    path: "/address",
    component: () => import("/workspace/pages/address.vue").then(m => m.default || m)
  },
  {
    name: "agent",
    path: "/agent",
    component: () => import("/workspace/pages/agent.vue").then(m => m.default || m)
  },
  {
    name: "apply",
    path: "/apply",
    component: () => import("/workspace/pages/apply.vue").then(m => m.default || m)
  },
  {
    name: "assets",
    path: "/assets",
    component: () => import("/workspace/pages/assets.vue").then(m => m.default || m)
  },
  {
    name: "coming-soon",
    path: "/coming-soon",
    component: () => import("/workspace/pages/coming-soon.vue").then(m => m.default || m)
  },
  {
    name: "completed",
    path: "/completed",
    component: () => import("/workspace/pages/completed.vue").then(m => m.default || m)
  },
  {
    name: "credit",
    path: "/credit",
    component: () => import("/workspace/pages/credit.vue").then(m => m.default || m)
  },
  {
    name: "down-payment",
    path: "/down-payment",
    component: () => import("/workspace/pages/down-payment.vue").then(m => m.default || m)
  },
  {
    name: "email",
    path: "/email",
    component: () => import("/workspace/pages/email.vue").then(m => m.default || m)
  },
  {
    name: "employment",
    path: "/employment",
    component: () => import("/workspace/pages/employment.vue").then(m => m.default || m)
  },
  {
    name: "finalize",
    path: "/finalize",
    component: () => import("/workspace/pages/finalize.vue").then(m => m.default || m)
  },
  {
    name: "first-time",
    path: "/first-time",
    component: () => import("/workspace/pages/first-time.vue").then(m => m.default || m)
  },
  {
    name: "income",
    path: "/income",
    component: () => import("/workspace/pages/income.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/workspace/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/workspace/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "name",
    path: "/name",
    component: () => import("/workspace/pages/name.vue").then(m => m.default || m)
  },
  {
    name: "not-found",
    path: "/not-found",
    component: () => import("/workspace/pages/not-found.vue").then(m => m.default || m)
  },
  {
    name: "partners-credit-services",
    path: "/partners/credit-services",
    component: () => import("/workspace/pages/partners/credit-services.vue").then(m => m.default || m)
  },
  {
    name: "partners-dovly",
    path: "/partners/dovly",
    component: () => import("/workspace/pages/partners/dovly.vue").then(m => m.default || m)
  },
  {
    name: "partners-naf",
    path: "/partners/naf",
    component: () => import("/workspace/pages/partners/naf.vue").then(m => m.default || m)
  },
  {
    name: "partners-novus",
    path: "/partners/novus",
    component: () => import("/workspace/pages/partners/novus.vue").then(m => m.default || m)
  },
  {
    name: "paying-rent",
    path: "/paying-rent",
    component: () => import("/workspace/pages/paying-rent.vue").then(m => m.default || m)
  },
  {
    name: "rates",
    path: "/rates",
    component: () => import("/workspace/pages/rates/index.vue").then(m => m.default || m)
  },
  {
    name: "rates-out",
    path: "/rates/out",
    meta: outzsTFaZAMqvMeta || {},
    component: () => import("/workspace/pages/rates/out.vue").then(m => m.default || m)
  },
  {
    name: "refer",
    path: "/refer",
    component: () => import("/workspace/pages/refer.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/workspace/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "start",
    path: "/start",
    meta: startEPNsMmPMMAMeta || {},
    component: () => import("/workspace/pages/start.vue").then(m => m.default || m)
  },
  {
    name: "submit",
    path: "/submit",
    component: () => import("/workspace/pages/submit.vue").then(m => m.default || m)
  },
  {
    name: "thank-you",
    path: "/thank-you",
    component: () => import("/workspace/pages/thank-you.vue").then(m => m.default || m)
  },
  {
    name: "ui-alert",
    path: "/ui/alert",
    component: () => import("/workspace/pages/ui/alert.vue").then(m => m.default || m)
  },
  {
    name: "ui-banner",
    path: "/ui/banner",
    component: () => import("/workspace/pages/ui/banner.vue").then(m => m.default || m)
  },
  {
    name: "ui-buttons",
    path: "/ui/buttons",
    meta: buttonsGfwAhs4SEsMeta || {},
    component: () => import("/workspace/pages/ui/buttons.vue").then(m => m.default || m)
  },
  {
    name: "ui-colors",
    path: "/ui/colors",
    meta: colors8szwstCzlKMeta || {},
    component: () => import("/workspace/pages/ui/colors.vue").then(m => m.default || m)
  },
  {
    name: "ui-didyouknow",
    path: "/ui/didyouknow",
    component: () => import("/workspace/pages/ui/didyouknow.vue").then(m => m.default || m)
  },
  {
    name: "ui",
    path: "/ui",
    meta: indexfHMywgrQ3bMeta || {},
    component: () => import("/workspace/pages/ui/index.vue").then(m => m.default || m)
  },
  {
    name: "ui-inputs",
    path: "/ui/inputs",
    meta: inputsXbtnwpYFkCMeta || {},
    component: () => import("/workspace/pages/ui/inputs.vue").then(m => m.default || m)
  },
  {
    name: "ui-letter",
    path: "/ui/letter",
    meta: letterPjw2T7fIAvMeta || {},
    component: () => import("/workspace/pages/ui/letter.vue").then(m => m.default || m)
  },
  {
    name: "ui-loading",
    path: "/ui/loading",
    component: () => import("/workspace/pages/ui/loading.vue").then(m => m.default || m)
  },
  {
    name: "ui-long-layout",
    path: "/ui/long-layout",
    component: () => import("/workspace/pages/ui/long-layout.vue").then(m => m.default || m)
  },
  {
    name: "ui-modal",
    path: "/ui/modal",
    component: () => import("/workspace/pages/ui/modal.vue").then(m => m.default || m)
  },
  {
    name: "ui-notice",
    path: "/ui/notice",
    component: () => import("/workspace/pages/ui/notice.vue").then(m => m.default || m)
  },
  {
    name: "ui-progress",
    path: "/ui/progress",
    component: () => import("/workspace/pages/ui/progress.vue").then(m => m.default || m)
  },
  {
    name: "ui-short-layout",
    path: "/ui/short-layout",
    component: () => import("/workspace/pages/ui/short-layout.vue").then(m => m.default || m)
  },
  {
    name: "ui-special",
    path: "/ui/special",
    meta: specialJbSKzMPS4pMeta || {},
    component: () => import("/workspace/pages/ui/special.vue").then(m => m.default || m)
  },
  {
    name: "ui-spinner",
    path: "/ui/spinner",
    component: () => import("/workspace/pages/ui/spinner.vue").then(m => m.default || m)
  },
  {
    name: "ui-testimonial",
    path: "/ui/testimonial",
    component: () => import("/workspace/pages/ui/testimonial.vue").then(m => m.default || m)
  },
  {
    name: "ui-Tooltip",
    path: "/ui/Tooltip",
    component: () => import("/workspace/pages/ui/Tooltip.vue").then(m => m.default || m)
  },
  {
    name: "ui-type",
    path: "/ui/type",
    meta: typeTVmbB7OFirMeta || {},
    component: () => import("/workspace/pages/ui/type.vue").then(m => m.default || m)
  },
  {
    name: "when-are-you",
    path: "/when-are-you",
    component: () => import("/workspace/pages/when-are-you.vue").then(m => m.default || m)
  },
  {
    name: "where-are-you",
    path: "/where-are-you",
    component: () => import("/workspace/pages/where-are-you.vue").then(m => m.default || m)
  },
  {
    name: "whos-it-for",
    path: "/whos-it-for",
    component: () => import("/workspace/pages/whos-it-for.vue").then(m => m.default || m)
  }
]